






























import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import DivReset from '@/components/atoms/DivReset.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import StudentList from '@/components/organisms/StudentList.vue'
import Conditions from '@/components/organisms/Conditions.vue'
import StudentInformationStatus, { StudentInfoType } from '@/components/organisms/StudentInformationStatus.vue'
import LessonInformation from '@/components/organisms/LessonInformation.vue'
import HomeworkInformation from '@/components/organisms/HomeworkInformation.vue'
import DivTitled from '@/components/molecules/DivTitled.vue'
import DivWithLine from '@/components/molecules/DivWithLine.vue'
import StudentComment from '@/components/organisms/StudentComment.vue'
import StudentGoalApi from '@/mixins/studentGoals/StudentGoalApi'
import { StudentGoal } from '@/models/api/studentGoal'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import _ from 'lodash'

@Component({
  components: {
    StudentList,
    TitleBase,
    DivReset,
    LabelBase,
    TitleTextBase,
    Conditions,
    StudentInformationStatus,
    LessonInformation,
    HomeworkInformation,
    DivTitled,
    DivWithLine,
    StudentComment,
  },
})
export default class StudentStatus extends Mixins(StudentGoalApi, ClassModeMethods) {
  private lessonData: {
    date?: string
    className?: string
    classSettingType?: string
    gradeName?: string
    subjectName?: string
    period?: number
  } = {}

  private studentData: StudentInfoType = {}

  private notCompletedHomeworks: string[] = []

  private historyLesson: {
    lessonId?: number
    date?: string
    name?: string
    period?: number
    point?: number
    curriculums?: {
      achievement: number
      rate: { min: number; max: number }
      text: string
    }[]
    supporterMemo?: string
  } = {}

  private studentListData: {
    code: string
    userId: number
    name: string
    lessonUserId: number
    icon: string
    selected: boolean
  }[] = []

  private studentReviewData?: { face?: number; comment?: string; iconDisable?: boolean } = {}

  private async mounted(): Promise<void> {
    await this.loadLessonData()
    await this.loadStudentListDatas()
  }

  private lessonId = this.$route.query.lessonId

  private async loadLessonData(): Promise<void> {
    await Vue.prototype.$http.httpWithToken.get(`/lessons/${this.lessonId}`).then((res: any) => {
      this.lessonData = res.data
    })
  }

  get lessonTitle(): string {
    if (this.lessonData) {
      return this.lessonData.className || ''
    }
    return ''
  }

  private async loadStudentListDatas(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .get('/lesson_users', { params: { lessonId: this.lessonId } })
      .then((res: any) => {
        this.studentListData = res.data.map((student: any) => {
          // TODO アイコン固定で暫定対応
          return {
            userId: student.userId,
            name: student.nickname,
            lessonUserId: student.lessonUserId,
            icon: '',
            selected: false,
          }
        })
        if (this.studentListData.length > 0) this.studentListData[0].selected = true
      })
  }

  @Watch('studentListData')
  async studentListDataChange(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.loadStudentData()
    await this.loadGoal()
    await this.loadStudentReviewData()
    Vue.prototype.$loading.complete()
  }

  private async loadStudentData(): Promise<void> {
    const student = this.studentListData.find((student) => student.selected)
    if (!student) return

    await Vue.prototype.$http.httpWithToken
      .get('lessonUsers/studentStatus', { params: { lessonId: this.lessonId, userId: student.userId } })
      .then((res: any) => {
        // 生徒情報
        const status = []
        const clasModeLabel = res.data.classModeShortName === '対策' ? 'alert' : 'information'
        status.push({ type: clasModeLabel, message: res.data.classModeShortName })
        // 宿題
        status.push({ type: 'information', message: `宿題：${res.data.homeworkNotSubmitted ? '未提出' : '提出済み'}` })
        this.notCompletedHomeworks = res.data.notCompletedHomeworks

        // TODO アイコン、目標とリマインドが未定なので固定で暫定対応
        this.studentData = {
          nickname: res.data.nickname,
          id: res.data.studentId,
          code: res.data.studentCode,
          iconUrl: '',
          school: res.data.schoolName,
          grade: res.data.gradeName,
          userId: student.userId,
          classModeCode: res.data.classModeCode,
          status: status,
        }

        // 前回の学習
        if (res.data.beforeLesson) {
          const curriculums = res.data.beforeLesson.curriculumSUnits.map((unit: any) => {
            return {
              achievement: Math.floor(unit.maxPredictedScore * 100) / 100,
              rate: {
                min: Math.floor(unit.minPredictedScore * 100) / 100,
                max: Math.floor(unit.maxPredictedScore * 100) / 100,
              },
              text: unit.curriculumSUnitName,
            }
          })
          this.historyLesson = {
            lessonId: res.data.beforeLesson.lessonId,
            date: res.data.beforeLesson.date,
            name: res.data.beforeLesson.name,
            point: res.data.beforeLesson.point,
            period: res.data.beforeLesson.period,
            curriculums: curriculums,
            supporterMemo: res.data.beforeLesson.supporterMemo
              ? res.data.beforeLesson.supporterMemo
              : 'サポーターメモはありません。',
          }
        } else {
          this.historyLesson = {}
        }
      })
  }

  private async loadStudentReviewData(): Promise<void> {
    const student = this.studentListData.find((student) => student.selected)
    if (!student || !this.historyLesson.lessonId) return

    await Vue.prototype.$http.httpWithToken
      .get('/lessonUsers/lookBack', {
        params: {
          lessonId: this.historyLesson.lessonId,
          userId: student.userId,
          classMode: this.studentData.classModeCode,
        },
      })
      .then((res: any) => {
        this.studentReviewData = {
          face: res.data.lookBackResult,
          comment: res.data.lookBackComment,
        }
        // コメント有無
        this.studentData.status?.splice(-1, 0, {
          type: 'information',
          message: _.get(res, 'data.lookBackComment') ? '前回コメント：あり' : '前回コメント：なし',
        })
      })
  }

  /**
   * 現在の目標取得
   */
  private async loadGoal() {
    const classModeCode = this.studentData.classModeCode || 'TJ'
    this.studentData.goal = await this.loadCurrentGoal(classModeCode, this.studentData.userId)
  }
}
