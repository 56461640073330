





import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DivWithLine extends Vue {
  @Prop({ default: null })
  color?: null | string

  @Prop({ default: null })
  size?: null | string

  get styles(): { [key: string]: string } {
    return {
      '--color': this.color == null ? 'var(--color-black)' : this.color,
      '--size': this.size == null ? 'var(--font-size-medium)' : this.size,
    }
  }
}
